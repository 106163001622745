import Vue from 'vue';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts from 'vue-apexcharts';
import router from './router/index';
import store from '@/state/store';
import vco from 'v-click-outside';
import VueMoment from 'vue-moment';
import VueConfirmDialog from 'vue-confirm-dialog';

import '@/design/index.scss';

import Sparkline from 'vue-sparklines';

import {initFirebaseBackend} from './authUtils';

const firebaseConfig = {
  apiKey: 'AIzaSyBxnb5eqg1X6b4ykb_8AbowmghW6nRVzH8',
  authDomain: 'sucoding-admin.firebaseapp.com',
  projectId: 'sucoding-admin',
  storageBucket: 'sucoding-admin.appspot.com',
  messagingSenderId: '460981993300',
  appId: '1:460981993300:web:8df188c07204e888ec12c1',
  measurementId: 'G-HWJXTTNTP0'
};

if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
  initFirebaseBackend(firebaseConfig);
}

Vue.use(BootstrapVue);
Vue.use(VueMoment);

Vue.config.productionTip = false;
Vue.use(VueConfirmDialog);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.use(require('vue-chartist'));
Vue.use(vco);
Vue.use(Sparkline);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places'
  },
  installComponents: true
});
Vue.component('apexchart', VueApexCharts);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
// Vue.config.devtools = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
