import {makeFormParams, request} from '../axios';
export const actions = {
  getSectionList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/section/get_section_list', {
        params
      });

      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  getSectionInfo(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/section/get_section_info', {
        params
      });
      console.log(result);
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  sectionAdd(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/section/add', makeFormParams(params));
      console.log(result);
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  deleteSection(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/section/delete_section', makeFormParams(params));
      console.log(result);
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  }
};
