import {getFirebaseBackend} from '../../authUtils.js';
import {request, makeUrlParams} from '../axios';
export const state = {
  currentUser: sessionStorage.getItem('authUser'),
  idToken: sessionStorage.getItem('idToken')
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState('auth.currentUser', newValue);
  }
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  }
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({state, dispatch}) {
    dispatch('validate');
  },

  // Logs in the current user.
  async logIn({commit, dispatch, getters}, {email, password} = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return await request
      .post(
        '/user/login',
        makeUrlParams({
          id: email,
          pw: password,
          authType: 'A'
        })
      )
      .then(res => {
        if (res.status === 200) {
          if (res.data.result === false) {
            throw new Error(res.data.message);
          } else {
            // commit('SET_TOKEN', res.data.token)
            sessionStorage.setItem('ACCESS_TOKEN', res.data.token.accessToken);
            sessionStorage.setItem('REFRESH_TOKEN', res.data.token.refreshToken);
          }
          return res.data.token.accessToken;
        }
      })
      .then(async token => {
        const res = await request.get('/user/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if ('user' in res.data) {
          sessionStorage.setItem('authUser', JSON.stringify(res.data.user));
          commit('SET_CURRENT_USER', res.data.user);
          return res.data.user;
        }

        throw new Error('user not found');
      });
    // return getFirebaseBackend()
    //   .loginUser(email, password)
    //   .then(response => {
    //     const user = response;
    //     commit("SET_CURRENT_USER", user);

    //     return user;
    //   });
  },

  // Logs out the current user.
  logOut({commit}) {
    // eslint-disable-next-line no-unused-vars
    commit('SET_CURRENT_USER', null);
    sessionStorage.removeItem('authUser');
    sessionStorage.removeItem('ACCESS_TOKEN');
    sessionStorage.removeItem('REFRESH_TOKEN');
    return true;

    // return new Promise((resolve, reject) => {
    //   // eslint-disable-next-line no-unused-vars
    //   getFirebaseBackend()
    //     .logout()
    //     .then(() => {
    //       resolve(true);
    //     })
    //     .catch(error => {
    //       reject(this._handleError(error));
    //     });
    // });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  register({commit, dispatch, getters}, {fullname, email, password} = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return getFirebaseBackend()
      .registerUser(email, password)
      .then(response => {
        const user = response;
        commit('SET_CURRENT_USER', user);
        return user;
      });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  resetPassword({commit, dispatch, getters}, {email} = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return getFirebaseBackend()
      .forgetPassword(email)
      .then(response => {
        const message = response.data;
        return message;
      });
  },

  myToken() {
    return getFirebaseBackend()
      .myIdToken()
      .then(response => {
        return response;
      });
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({commit, state}) {
    if (!state.currentUser) return Promise.resolve(null);
    const user = getFirebaseBackend().getAuthenticatedUser();
    commit('SET_CURRENT_USER', user);
    return user;
  }
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
