import {request, makeFormParams} from '../axios';

export const actions = {
  // 결제내역 가져오기
  getPaymentList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/payment/paymentList', {
        params
      });
      if (result.data.status === 200) resolve(result.data);
      else reject(result);
    });
  },

  // 결제 취소하기
  cancelPayment(context, params){
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/payment/cancelPayment', makeFormParams(params));
      if (result.data.status === 200) resolve(result.data);
      else reject(result);
    });
  }
};
