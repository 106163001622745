import {request, makeFormParams} from '../axios';

export const state = () => ({
  page: 1,
  searchKeyword: ''
});

export const mutations = {
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_SEARCH_KEYWORD(state, keyword) {
    state.searchKeyword = keyword;
  }
};

export const actions = {
  //  쿠폰 리스트 가져오기
  getCouponList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/coupon/get_coupon', {
        params
      });
      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  // 쿠폰 등록
  addCoupon(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/coupon/add', makeFormParams(params), {
        headers: {'Content-Type': 'multipart/form-data'}
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
   //  상세 정보
   getCouponInfo(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/coupon/get_coupon_info', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  deleteCoupon(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/coupon/deleteCoupon', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
}