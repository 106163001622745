import {request, makeFormParams} from '../axios';

export const actions = {
  bannerList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/banner/banner_list', {
        params
      });
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },

  bannerDetailInfo(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/banner/banner_detail_info', {
        params
      });
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },

  bannerAdd(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/banner/banner_add', makeFormParams(params));

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },

  bannerDelete(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/banner/banner_delete', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  }
};
