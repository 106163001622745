// import { request } from "@/state/axios";
import axios from "axios";
import router from "@/router";
// refresh Token 갱신
export const refreshToken = async () => {
  // request 태우니까 같은 인터셉터를 사용해서 무한 호출이 되버림
  // 그래서 어쩔 수 없이 별도의 axios로 요청하게 로직 수정

  return new Promise(async resolve => {
    await axios
      .post("https://api.sucoding.kr/user/refresh", {
        "token.refreshToken": sessionStorage.getItem("REFRESH_TOKEN")
      })
      .then(result => {
        console.log("refresh rsult");
        console.log(result);
        sessionStorage.setItem("ACCESS_TOKEN", result.data.token.accessToken);
        resolve(result.data.token.accessToken);
      })
      .catch(() => {
        sessionStorage.removeItem("authUser");
        sessionStorage.removeItem("ACCESS_TOKEN");
        sessionStorage.removeItem("REFRESH_TOKEN");
        return router.push("/login");
      });
  });
};
