import {request, makeFormParams, makeUrlParams} from '../axios';

export const state = () => ({
  page: 1,
  courseId: '',
  sectionId: '',
  searchKeyword: '',
  type: 'qna'
});

export const mutations = {
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_COURSE_ID(state, courseId) {
    state.courseId = courseId;
  },
  SET_SECTION_ID(state, sectionId) {
    state.sectionId = sectionId;
  },
  SET_SEARCH_KEYWORD(state, keyword) {
    state.searchKeyword = keyword;
  },
  SET_TYPE(state, keyword) {
    state.type = keyword;
  }
};

export const actions = {
  //  코스 리스트 가져오기
  getQnaList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/community/qna', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },

  getQnaData(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/community/qnaData', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },

  // 특정 코스&섹션에 속한 게시글 제목
  getQnaSectionList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/get_course_in_section_post', {
        params
      });

      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },

  // 공개 여부 상태 변경하기
  qnaOpenChange(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/community/changeQnaOpen', makeFormParams(params));

      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  replyList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/getPostQnaReplyList', {params});

      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  replyModify(contenxt, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/modifyReply', makeUrlParams(params));

      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  deleteCommunityItem(contenxt, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/deleteQna', makeUrlParams(params));

      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  }
};
