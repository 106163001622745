import {request, makeFormParams} from '../axios';

export const state = () => ({
  page: 1,
  courseId: '',
  sectionId: '',
  searchKeyword: ''
});
export const mutations = {
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_COURSE_ID(state, courseId) {
    state.courseId = courseId;
  },
  SET_SECTION_ID(state, sectionId) {
    state.sectionId = sectionId;
  },
  SET_SEARCH_KEYWORD(state, keyword) {
    state.searchKeyword = keyword;
  }
};
export const actions = {
  //  코스 리스트 가져오기
  getPostList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/get_post', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },

  // 게시글 상세 정보
  getPostInfo(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/get_post_info', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  // 관리자 포스팅 게시글 추가
  addPost(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/add', makeFormParams(params), {
        headers: {'Content-Type': 'multipart/form-data'}
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  //작성하려고 하는 코스 & 섹션의 게시글 마지막 번호를 가져옴
  getPostOrderNumber(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/get_post_order_number', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  removeRunImg(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/deleteRunImg', makeFormParams(params));

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  deletePost(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/deletePost', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  getUserQuiz(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/userQuiz', {params});
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  getHomework(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/userQuizInfo', {params});
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  quizReplyAdd(context, params) {
    // 숙제 상세 리플
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/addQuizReply', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  quizReplyModify(contenxt, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/modifyQuizReply', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  quizReplyDelete(contenxt, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/deleteQuizReply', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  quizReplyInReplyAdd(context, params) {
    // 숙제 상세 리플
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/quizReplyInReplyAdd', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  quizRreplyDelete(context, params) {
    // 숙제 상세 리플
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/quizRreplyDelete', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  activeCode(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/activeCode', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  deleteHomework(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/deleteHomework', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  homeWorkStatus(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/post/homeWorkStatus', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  getReplyNoRead(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/qnaReplyNoRead', {params});
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
  /** 리플 읽었음 처리 */
  replyReadConfirm(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/post/replyReadConfirm', {params});
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  }
};
