import axios from "axios";
import { refreshToken } from "@/service";
import jwt_decode from "jwt-decode";

export const request = axios.create({
  baseURL: "https://api.sucoding.kr"
});

request.interceptors.request.use(async function(config) {
  const token = sessionStorage.getItem("ACCESS_TOKEN");

  if (token !== null && token !== "") {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    const decoded = jwt_decode(token);
    const expiry =
      decoded.exp -
      Number(
        new Date()
          .getTime()
          .toString()
          .substr(0, 10)
      );

    if (expiry < 30) {
      await refreshToken()
        .then(accessToken => {
          console.log("new access token" + accessToken);
          config.headers.Authorization = token ? `Bearer ${accessToken}` : "";
        })
        .catch(() => {
          console.log("refresh error");
        });
    }
  }

  return config;
});

request.interceptors.response.use(response => {
  return response;
});

export const makeUrlParams = params => {
  const urlSearchParam = new URLSearchParams();

  for (const key in params) {
    if (typeof params[key] === "string") {
      urlSearchParam.append(key, String(params[key]));
    } else if (typeof params[key] === "number") {
      urlSearchParam.append(key, Number(params[key]));
    } else {
      urlSearchParam.append(key, Array(params[key]));
    }
  }

  return urlSearchParam;
};

export const makeFormParams = params => {
  const formData = new FormData();

  for (const key in params) {
    if (typeof params[key] === "string") {
      formData.append(key, String(params[key]));
    } else if (typeof params[key] === "number") {
      formData.append(key, Number(params[key]));
    } else if (params[key] === null) {
      formData.append(key, "");
    } else {
      formData.append(key, params[key]);
    }
  }

  return formData;
};
