import {request} from '../axios';

export const state = {
  user: ''
};

export const actions = {
  getUserList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/user/userList', {
        params
      });
      if (result.data.status === 200) resolve(result);
      else reject(result);
    });
  },
  // 총 유저 수(관리자 제외)
  getUserCount(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/user/userCount', {
        params
      });
      // console.log(result);
      if (result.status === 200) resolve(result.data);
      else reject(result);
    });
  },
  // 회원 탈퇴
  outMember(contenxt, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/user/outMember', {
        params
      });
      // console.log(result);
      if (result.status === 200) resolve(result.data);
      else reject(result);
    });
  },
  // 회원 정지
  stopMember(contenxt, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/user/stopMember', {
        params
      });
      // console.log(result);
      if (result.status === 200) resolve(result.data);
      else reject(result);
    });
  }
};
