import {request, makeFormParams} from '../axios';

export const state = () => ({
  page: 1,
  courseId: '',
  sectionId: '',
  searchKeyword: ''
});

export const mutations = {
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_COURSE_ID(state, courseId) {
    state.courseId = courseId;
  },
  SET_SECTION_ID(state, sectionId) {
    state.sectionId = sectionId;
  },
  SET_SEARCH_KEYWORD(state, keyword) {
    state.searchKeyword = keyword;
  }
};

export const actions = {
  //  게시글 리스트 가져오기
  getNoticeList(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/notice/get_notice', {
        params
      });
      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  // 게시글 등록
  addNotice(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/notice/add', makeFormParams(params), {
        headers: {'Content-Type': 'multipart/form-data'}
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
   // 게시글 상세 정보
   getNoticeInfo(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.get('/notice/get_notice_info', {
        params
      });

      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result);
      }
    });
  },
  deleteNotice(context, params) {
    return new Promise(async (resolve, reject) => {
      const result = await request.post('/notice/deleteNotice', makeFormParams(params));
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject(result);
      }
    });
  },
}